abbr:where([title]) {
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
}

.react-calendar {
  border: none;
  font-family: inherit;
}

.react-calendar button {
  color: #adadad;
}
.react-calendar__tile--now {
  background: none;
  color: #333333 !important;
}
.react-calendar__month-view__weekdays {
  color: #333333;
  text-transform: none !important;
  font-size: 10px !important;
  font-style: none !important;
  text-decoration: none !important;
  font-weight: 500;
  border: none !important;
}
.react-calendar__tile--active {
  background: #00a991 !important;
}
.react-calendar .react-calendar__tile--activee {
  background: none;
}
.react-calendar .react-calendar__tile--today {
  background: none !important;
}
.react-calendar__tile--active:enabled:hover {
  background: #00a991 !important;
}
.react-calendar .react-calendar__tile--active abbr {
  color: #fff !important;
  font-weight: 700;
}

.react-calendar__tile abbr {
  color: #333333 !important;
  font-weight: 500;
  font-size: 10px !important;
}
.react-calendar__navigation {
  margin-bottom: 0 !important;
}
.react-calendar__navigation button {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
}

.react-calendar__tile {
  display: flex;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  padding: 4px 0px;
  width: 18px;
  height: 19px;
}

.react-calendar__month-view__days {
  gap: 0px;
}

.react-calendar__tile:active,
.react-calendar__tile:focus {
  background: #00a991 !important;
  color: white !important;
}
